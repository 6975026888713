<template>
	<div>
		<BRow class="mt-5 justify-content-center">
			<BCol lg="8">
				<FontAwesomeIcon :icon="['fal', 'exclamation-circle']" class="icon w-100 mb-3" />
				<h1 class="text-center mb-2">{{ $t('title') }}</h1>
			</BCol>
		</BRow>
		<BRow class="justify-content-center">
			<BCol lg="6">
				<BCard class="mb-4">
					<p class="h5 mb-0">{{ $t('info.originalCopies') }}</p>
					<p class="mb-2">{{ $t('info.sendThemTo') }}</p>
					<address>
						{{ $t('info.mailingAddress.line1') }}<br />
						{{ $t('info.mailingAddress.line2') }}<br />
						{{ $t('info.mailingAddress.line3') }}<br />
					</address>
					<p class="h5 mb-0">{{ $t('info.unsure') }}</p>
					<p class="mb-0">{{ $t('info.whatYouNeed') }}</p>
				</BCard>
				<div class="mb-5">
					<BaseButton
						class="mr-3 mb-3 mb-sm-0"
						:label="$t('label.moreInfo')"
						variant="primary"
						:icon="['fal', 'external-link-alt']"
						icon-position="right"
						automation-id="moreInfoButton"
						@click="openTravelLink()"
					/>
					<BaseButton
						:label="$t('label.homePage')"
						variant="link"
						:icon="['fal', 'angle-left']"
						icon-position="left"
						automation-id="homePageButton"
						@click="openHomePageLink()"
					/>
				</div>
			</BCol>
		</BRow>
	</div>
</template>

<script>
import IdMixin from '@/mixins/id';
import { SUBMIT_CLAIM } from '@/constants/Routes.js';
import { BRow, BCol, BCard } from 'bootstrap-vue';
import BaseButton from '@/components/common/base/BaseButton.vue';
export default {
	name: 'TravelCanNotProcessPage',
	components: {
		BRow,
		BCol,
		BCard,
		BaseButton
	},
	mixins: [IdMixin],

	methods: {
		openTravelLink() {
			if (this.$store.state.i18n.locale === 'fr') {
				window.open(
					'https://www.medaviebc.ca/fr/nos-regimes/assurance-voyage/formulaires-de-assurance-voyage',
					'_blank'
				);
			} else {
				window.open(
					'https://www.medaviebc.ca/en/plans/travel/travel-insurance-claim-forms',
					'_blank'
				);
			}
		},

		openHomePageLink() {
			this.$router.push({ name: SUBMIT_CLAIM.CLAIM_TYPE });
		}
	}
};
</script>

<style lang="scss" scoped>
.icon {
	font-size: 42px;

	& path {
		color: $orange-dark;
	}
}
</style>

<i18n>
{
  "en": {
    "title": "Sorry, your claim can't be processed!",
    "info": {
      "originalCopies": "In your province, we require original copies of your completed forms and other required documents.",
      "sendThemTo": "You can send them to:",
      "mailingAddress": {
        "line1": "Medavie Blue Cross Travel Claims",
        "line2": "PO Box 3888, Station B",
        "line3": "Montreal, QC, H3B 3L7."
      },
      "unsure": "Unsure what you need?",
      "whatYouNeed": "You must mail your completed travel claim form as well as photos or scans of relevant things like itemized bills, detailed pharmacy receipts, proof of payment, medical reports, and so on. Our website has more details at the link below."
    },
    "label": {
      "moreInfo": "Forms and more info",
      "homePage": "Back"
    }
  },
  "fr": {
    "title": "Désolé, votre demande de règlement ne peut pas être traitée.",
    "info": {
      "originalCopies": "Dans votre province, les formulaires remplis et les documents demandés doivent être fournis en version originale.",
      "sendThemTo": "Vous pouvez les faire parvenir à l'addresse suivante :",
      "mailingAddress": {
        "line1": "Assistance Voyage Croix Bleue,",
        "line2": "CP 3888, Succursale B",
        "line3": "Montréal, QC, H3B 3L7."
      },
      "unsure": "Vous ne savez pas quels documents sont requis?",
      "whatYouNeed": "Vous devez envoyer votre formulaire de demande de règlement d'assurance voyage ainsi qu'une photo ou une copie numérisée des documents pertinents comme les factures détaillées, les reçus de pharmacie détaillés, les preuves de paiement, les rapports médicaux, etc. Vous trouverez plus de renseignements sur notre site Web en cliquant sur le lien ci-dessous."
    },
    "label": {
      "moreInfo": "Consultez le site maintenant",
      "homePage": "Précédent"
    }
  }
}
</i18n>
